import { useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import ButtonTooltip from '../../ButtonTooltip';
import { Button } from '../../DesignSystem/Inputs/Buttons/ButtonAction';
import Pills from '../../DesignSystem/Pills';
import { PillsColors } from '../../DesignSystem/Pills/interface';
import Popover from '../../DesignSystem/Popover';
import { Tags } from '../../DesignSystem/Tags';
import { TagsColor } from '../../DesignSystem/Tags/interface';
import { FontWeight, Paragraph } from '../../DesignSystem/Typography';
import { TableCellProps } from './interface';
import styles from './styles.module.scss';

export function TableCell({ type, value }: TableCellProps): JSX.Element {
  const buttonRef = useRef(null);
  const [copyStatus, setCopyStatus] = useState<boolean>(false);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 1000);
  };

  switch (type) {
    case 'tag':
      return (
        <div>
          <Tags color={TagsColor.primary}>{value.message}</Tags>
        </div>
      );
    case 'qr_code':
      return (
        <div className={styles['qr-code-cell']}>
          <div>
            <QRCode value={value.message} size={77} />
          </div>
          <Paragraph size={2} weight={FontWeight.semiBold} ellipsis={1}>
            {value.message}
          </Paragraph>
        </div>
      );
    case 'pill':
      return (
        <div>
          {value.message && (
            <Pills color={PillsColors.primary} size="lg" {...value.pill}>
              {value.message}
            </Pills>
          )}
        </div>
      );
    case 'tooltip':
      return (
        <div className={styles['cell-with-button']}>
          <ButtonTooltip tooltip={value.buttonTooltipProps?.tooltip || ''} {...value.buttonTooltipProps}>
            {value.message}
          </ButtonTooltip>
        </div>
      );
    case 'popover':
      return (
        <div className={styles['cell-with-button']}>
          <Button
            firstIcon={
              value.enable ? { children: 'mark_email_read' } : { children: 'cancel_schedule_send', color: 'red-block' }
            }
            ref={buttonRef}
            variant="tertiary"
            size="sm"
            onMouseEnter={() => setShowPopover(true)}
            onMouseLeave={() => setShowPopover(false)}
          />
          <Popover
            callback={() => setShowPopover(false)}
            hiddenOverlay
            showPopover={showPopover}
            propRef={buttonRef}
            color="dark"
            placement="left-start"
            mainStyle={{ display: 'grid', gap: '.25rem', padding: '.75rem' }}
            size="sm"
          >
            <>
              <Paragraph size={3} weight={FontWeight.extraBold}>
                {value.header}
              </Paragraph>
              <Paragraph size={3} weight={FontWeight.semiBold} color={'gray-24'}>
                {value.message}
              </Paragraph>
            </>
          </Popover>
        </div>
      );

    case 'copy':
      return (
        <div>
          <Paragraph size={2} weight={FontWeight.semiBold}>
            {value.message}
          </Paragraph>
          <Button
            variant="tertiary"
            size="sm"
            firstIcon={copyStatus ? { children: 'check', color: 'green-status-hover' } : { children: 'copy_all' }}
            text={{ color: 'dark' }}
            onClick={() => handleCopy(value.message)}
          />
        </div>
      );
    default:
      return (
        <div className={styles['cell-text']}>
          <Paragraph size={2} weight={FontWeight.semiBold} ellipsis={1}>
            {value.message}
          </Paragraph>
        </div>
      );
  }
}
