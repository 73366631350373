import { useLocation } from 'react-router-dom';
import ButtonNavigation from '../Inputs/Buttons/ButtonNavigation';
import { TabNavigationProps } from './interface';
import styles from './styles.module.scss';

export default function TabNavigation({ tabItems }: TabNavigationProps): JSX.Element {
  const location = useLocation();
  return (
    <nav className={styles['tab-wrapper']}>
      {tabItems
        .filter((item) => !item.hide)
        .map((item, index) => (
          <ButtonNavigation
            key={`tab-item-${index}`}
            {...(!!location.pathname.includes(item.link) && { activeClassName: styles.active })}
            to={item.link}
            exact={true}
            navlink={true}
            variant="tertiary"
            {...(item.disabled && { disabled: item.disabled })}
            {...(item.icon && { firstIcon: { children: item.icon } })}
          >
            {item.name}
          </ButtonNavigation>
        ))}
    </nav>
  );
}
