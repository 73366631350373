import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Button } from '../Inputs/Buttons/ButtonAction';
import { IconButton } from '../Inputs/IconButton';
import Overlay from '../Overlay';
import { DropdownProps } from './interface';
import styling from './styles.module.scss';

export default function Dropdown({
  header,
  placement,
  activeButton,
  buttonsFooter,
  size,
  children,
}: DropdownProps): JSX.Element {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [showDropdownAnimation, setShowDropdownAnimation] = useState<boolean>(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  const arrowRef = useRef(null);
  const { styles, attributes } = usePopper(buttonRef.current, dropdownRef.current, {
    placement: placement ?? 'auto',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      {
        name: 'arrow',
        options: {
          element: arrowRef.current,
        },
      },
    ],
  });

  useEffect(() => {
    const closeDropdown = () => {
      setShowDropdown(false);
    };

    const buttons = document.querySelectorAll('[data-close="true"]');
    buttons.forEach((button) => {
      button.addEventListener('click', closeDropdown);
    });

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener('click', closeDropdown);
      });
    };
  }, []);

  const handleShowDropdown = () => {
    if (!showDropdown) {
      setShowDropdown(true);
      setTimeout(() => setShowDropdownAnimation(true), 200);
    } else {
      setShowDropdownAnimation(false);
      setTimeout(() => setShowDropdown(false), 200);
    }
  };

  return (
    <>
      {showDropdown && <Overlay callback={handleShowDropdown} />}
      {activeButton.button && (
        <Button {...activeButton.button} ref={buttonRef} onClick={handleShowDropdown}>
          {activeButton?.button.children}
        </Button>
      )}
      {activeButton.iconButton && (
        <IconButton {...activeButton.iconButton} ref={buttonRef} onClick={handleShowDropdown}>
          {activeButton?.iconButton.children}
        </IconButton>
      )}
      {activeButton.custom && activeButton.custom}
      {showDropdown && (
        <div
          className={[
            styling['dropdown-wrapper'],
            size ? styling[size] : '',
            styling[showDropdownAnimation ? 'fade-in' : 'fade-out'],
          ].join(' ')}
          ref={dropdownRef}
          style={styles.popper}
          {...attributes.popper}
        >
          {header && (
            <header>
              <span>{header.title}</span>
              {header.button && (
                <Button {...header.button} variant="tertiary" size="sm">
                  {header.button.children}
                </Button>
              )}
            </header>
          )}
          <main>{children}</main>
          {buttonsFooter && buttonsFooter.length > 0 && (
            <footer>
              {buttonsFooter.map((button, index) => (
                <Button key={`button-footer-dropdown-${index}`} {...button}>
                  {button?.children}
                </Button>
              ))}
            </footer>
          )}
        </div>
      )}
    </>
  );
}
