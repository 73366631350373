import { useEffect, useRef, useState } from 'react';
import styleModuleClasses from '../../utils/styleModuleClasses';
import Dropdown from '../DesignSystem/Dropdown';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import { FontWeight, Paragraph } from '../DesignSystem/Typography';
import { TableCell } from './Cell';
import SkeletonTable from './SkeletonTable';
import { TableColumnsControlProps, TableDataCellSerialized, TableHeaderElement } from './interface';
import styles from './styles.module.scss';

export default function TableColumnsControl({
  column,
  toggleColumns,
  loading,
  actionButtons,
  selectLine,
  lineSize,
  firstColumnFixed,
  lastColumnFixed,
}: TableColumnsControlProps): JSX.Element {
  const [lineSelected, setLineSelected] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [shadowFirstColumn, setShadowFirstColumn] = useState<boolean>(false);
  const [shadowLastColumn, setShadowLastColumn] = useState<boolean>(false);

  const handleScroll = () => {
    if (contentRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = contentRef.current;
      const right = scrollLeft + clientWidth;

      if (scrollLeft === 0) {
        setShadowLastColumn(true);
        setShadowFirstColumn(false);
      }
      if (scrollLeft > 0 && right < scrollWidth) {
        setShadowFirstColumn(true);
        setShadowLastColumn(true);
      }

      if (right === scrollWidth) {
        if (scrollLeft > 0) {
          setShadowFirstColumn(true);
        }
        setShadowLastColumn(false);
      }
    }
  };

  useEffect(() => {
    if (!loading) {
      handleScroll();
    }
  }, [loading]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', handleScroll);
      return () => {
        if (contentRef.current) {
          contentRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  const handleColumn = (id: string) => {
    const columnUpdated = column?.map((item) => {
      if (item.id === id) {
        return { ...item, enabled: !item.enabled };
      }
      return item;
    });
    const columnIdList = columnUpdated?.filter((item) => item.enabled === true).map((item) => item.id);

    toggleColumns?.(columnUpdated, columnIdList?.join(','));
  };

  const checkBoxColumnIndex = column?.findIndex((item) => item.id === selectLine?.columnId) || 0;

  const handleSelected = (id: string) => {
    const index = lineSelected.indexOf(id);
    const updateLines = index >= 0 ? lineSelected.filter((lineSelected) => lineSelected !== id) : [...lineSelected, id];
    setLineSelected(updateLines);
    selectLine?.handleCheckboxSelected?.(updateLines);

    if (updateLines.length === column?.[checkBoxColumnIndex].data?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  };

  const selectAll = () => {
    if (!allChecked) {
      const values = column?.[checkBoxColumnIndex].data?.map((value) => value.message);
      setLineSelected(values || []);
      selectLine?.handleCheckboxSelected?.(values || []);
    } else {
      setLineSelected([]);
      selectLine?.handleCheckboxSelected?.([]);
    }
    setAllChecked(!allChecked);
  };

  useEffect(() => {
    if (loading || column === undefined) {
      setLineSelected([]);
      setAllChecked(false);
    }
  }, [loading, column]);

  return (
    <div
      className={styleModuleClasses(styles, 'table', lineSize, {
        'shadow-first-column': !loading && firstColumnFixed && shadowFirstColumn,
        'shadow-last-column': !loading && lastColumnFixed && shadowLastColumn,
        'skeleton-table': loading || column === undefined,
      })}
      ref={contentRef}
    >
      {loading || column === undefined ? (
        <SkeletonTable />
      ) : (
        <>
          <header
            className={styleModuleClasses(styles, {
              'first-column-fixed': firstColumnFixed,
              'last-column-fixed': lastColumnFixed,
            })}
          >
            {!!selectLine && (
              <div className={styles['column-checkbox']}>
                <input type="checkbox" onChange={selectAll} checked={allChecked} />
              </div>
            )}
            {column.map(
              (eachColumn, index) =>
                eachColumn.enabled && (
                  <div
                    key={`header-${index}`}
                    {...(eachColumn.minWidth ? { style: { minWidth: eachColumn.minWidth } } : undefined)}
                    {...(eachColumn.width ? { style: { width: eachColumn.width } } : undefined)}
                  >
                    {eachColumn.header}
                  </div>
                ),
            )}
            <div className={styles['alignment-column']}>&nbsp;</div>
            <div>
              <div>
                {!!toggleColumns && (
                  <>
                    <Dropdown
                      activeButton={{
                        button: {
                          size: 'sm',
                          variant: 'secondary',
                          firstIcon: { children: 'view_column' },
                          lastIcon: { children: 'keyboard_arrow_down', color: 'gray-56' },
                        },
                      }}
                      placement="bottom-end"
                    >
                      <div className={styles['wrapper-manage-filters']}>
                        {column.map((eachColumn) => (
                          <Button
                            fullWidth
                            variant="tertiary"
                            text={{ color: 'dark', align: 'between' }}
                            lastIcon={{
                              children: eachColumn.enabled ? 'visibility' : 'visibility_off',
                              ...(eachColumn.enabled && { color: 'primary' }),
                            }}
                            key={eachColumn.header}
                            {...((column.filter((eachColumn) => !!eachColumn.enabled).length > 1 ||
                              !eachColumn.enabled) && {
                              onClick: () => {
                                handleColumn(eachColumn.id);
                              },
                            })}
                          >
                            <Paragraph size={2} weight={FontWeight.semiBold}>
                              {eachColumn.header}
                            </Paragraph>
                          </Button>
                        ))}
                      </div>
                    </Dropdown>
                  </>
                )}
              </div>
            </div>
          </header>
          <main
            className={styleModuleClasses(styles, {
              'first-column-fixed': firstColumnFixed,
              'last-column-fixed': lastColumnFixed,
            })}
          >
            {!!selectLine && checkBoxColumnIndex && (
              <div className={styleModuleClasses(styles, 'column', 'column-checkbox')}>
                {column[checkBoxColumnIndex].data?.map((value, index) => {
                  return (
                    <div key={`checkbox-${index}`}>
                      <input
                        type="checkbox"
                        checked={lineSelected.indexOf(value.message) >= 0}
                        onChange={() => handleSelected(value.message)}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {column.map(
              (eachColumn, index) =>
                eachColumn.enabled && (
                  <div
                    className={styles['column']}
                    key={`column-${index}`}
                    {...(eachColumn.minWidth ? { style: { minWidth: eachColumn.minWidth } } : undefined)}
                    {...(eachColumn.width ? { style: { width: eachColumn.width } } : undefined)}
                  >
                    {eachColumn.data?.map((eachData, index) => (
                      <TableCell key={`cell-${index}`} type={eachData.type || eachColumn.type} value={eachData} />
                    ))}
                  </div>
                ),
            )}

            <div className={`${styles['column']} ${styles['alignment-column']}`}>
              {column[0].data?.map((_, index) => (
                <div key={`alignment-column-${index}`} className={styles['alignment-column']}>
                  &nbsp;
                </div>
              ))}
            </div>

            <div className={styles['column']}>
              {column[0].data?.map((_, index) => (
                <div key={`cta-${index}`}>
                  <div className={styles['wrapper-cta']}>{Array.isArray(actionButtons) && actionButtons[index]}</div>
                </div>
              ))}
            </div>
          </main>
        </>
      )}
    </div>
  );
}

export function buildTableResult(
  header: TableHeaderElement[],
  contentTable: TableDataCellSerialized[],
): TableHeaderElement[] {
  return header.map((column) => {
    const data = contentTable.map((item) => {
      return {
        message: (item[column.id as keyof TableDataCellSerialized] || '').toString(),
      };
    });
    return { ...column, data };
  });
}

export function filterTableColumns(header: TableHeaderElement[], tableColumns?: string): TableHeaderElement[] {
  const tableColumnsArray = tableColumns?.split(',');

  if (!tableColumnsArray) {
    return header;
  }

  return header.map((column) => ({
    ...column,
    enabled: tableColumnsArray.includes(column.id),
  }));
}
