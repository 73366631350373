import React from 'react';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import Popover from '../../Popover';
import { Button } from '../Buttons/ButtonAction';
import { InputProps } from './interface';
import styles from './styles.module.scss';

export default React.forwardRef<HTMLInputElement, InputProps>(function Input(
  { label, inputSize, fullwidth, value, icon, color, classname, popover, button, helperText, ...rest }: InputProps,
  ref,
): JSX.Element {
  return (
    <div
      className={styleModuleClasses(styles, 'form-wrapper', inputSize, color, {
        fullwidth: !!fullwidth,
        classname: !!classname,
      })}
    >
      {(label || popover) && (
        <header>
          {label && <label>{label}</label>}
          {button && <Button {...button} />}
          {popover && <Popover {...popover} />}
        </header>
      )}
      <div className={styles['input-wrapper']}>
        <input ref={ref} value={value} type="text" {...rest} />
        {icon?.name && (
          <span
            className={[
              'material-symbols-rounded',
              styles[icon.color ?? ''],
              !icon?.onClick ? styles['disabled'] : '',
            ].join(' ')}
            onClick={icon.onClick ?? undefined}
            style={icon.style}
          >
            {icon.name}
          </span>
        )}
      </div>
      {helperText && <span className={styles['helper-text']}>{helperText}</span>}
    </div>
  );
});
