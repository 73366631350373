import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../../components/Pagination/paginator.gql';

export const promotionFragment = gql`
  fragment promotion_Part on Promotion {
    id
    account_id
    name
    campaign_name
    slug
    start_date
    end_date
    visibility
    status
    microsite_url
    external_domain
    is_locked
    created_date
    passcode
    passcode_time
    current_time
    domain_type
    enable_custom_domain
    custom_msg_flag
    custom_msg_no_invite
    custom_msg_invalid_invite
    result_access_enable
    result_access_limit
    custom_msg_input_invite
    custom_msg_reaccess
    render
    totalLive
  }
`;

export const GET_ACCOUNT_BY_SLUG = gql`
  query accountFindBySlug($agency_id: ID!, $account_slug: String!) {
    accountFindBySlug(agency_id: $agency_id, account_slug: $account_slug) {
      id
      agency_id
    }
  }
`;

export const GET_PROMOTION = gql`
  query promotion($account_id: ID!, $promo_id: ID!) {
    promotion(account_id: $account_id, promo_id: $promo_id) {
      ...promotion_Part
    }
  }
  ${promotionFragment}
`;

export const UPDATE_PROMOTE_START_DATE = gql`
  mutation updatePromotionStartDate(
    $account_id: ID!
    $promo_id: ID!
    $start_date: String
    $visibility: String
    $customMessageFlag: Boolean
    $customMsgNoInvite: String
    $customMsgInvalidInvite: String
    $resultAccessEnable: Boolean
    $resultAccessLimit: Int
    $method: String
    $customMsgInputInvite: String
    $customMsgReaccess: String
  ) {
    updatePromotionStartDate(
      account_id: $account_id
      promo_id: $promo_id
      start_date: $start_date
      visibility: $visibility
      customMessageFlag: $customMessageFlag
      customMsgNoInvite: $customMsgNoInvite
      customMsgInvalidInvite: $customMsgInvalidInvite
      resultAccessEnable: $resultAccessEnable
      resultAccessLimit: $resultAccessLimit
      method: $method
      customMsgInputInvite: $customMsgInputInvite
      customMsgReaccess: $customMsgReaccess
    ) {
      ...promotion_Part
    }
  }
  ${promotionFragment}
`;

export const UPDATE_PROMOTE_END_DATE = gql`
  mutation updatePromotionEndDate($account_id: ID!, $promo_id: ID!, $end_date: String!) {
    updatePromotionEndDate(account_id: $account_id, promo_id: $promo_id, end_date: $end_date) {
      ...promotion_Part
    }
  }
  ${promotionFragment}
`;

export const CREATE_INDIVIDUALLY_INVITE = gql`
  mutation createIndividuallyInvite(
    $agency_id: ID!
    $account_id: ID!
    $promo_id: ID!
    $data: String
    $expires_at: String
    $uid_field_slug: String
    $uid: String
  ) {
    createIndividuallyInvite(
      agency_id: $agency_id
      account_id: $account_id
      promo_id: $promo_id
      data: $data
      expires_at: $expires_at
      uid_field_slug: $uid_field_slug
      uid: $uid
    ) {
      id
      url
      code
      data
      created_at
      uid
      expires_at
      expire_date
    }
  }
`;

export const GET_INVITES = gql`
  query inviteLinks($agency_id: ID!, $account_id: ID!, $promo_id: ID!, $page: Int, $filters: InviteLinksFilter) {
    inviteLinks(agency_id: $agency_id, account_id: $account_id, promo_id: $promo_id, page: $page, filters: $filters) {
      data {
        id
        agency_id
        account_id
        code
        data
        url
        promotion_id
        generator_id
        uid_field_slug
        uid
        expires_at
        created_at
        updated_at
        used_at
        is_used
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const LIST_FIELDS = gql`
  query fields($agency_id: ID!, $account_id: ID!) {
    fields(agency_id: $agency_id, account_id: $account_id) {
      data {
        id
        name
        slug
        base_field
        is_uid_field
      }
    }
  }
`;

export const CREATE_INBULK_QUANTITY_INVITE = gql`
  mutation createInbulkQuantityInvite(
    $agency_id: ID!
    $account_id: ID!
    $promo_id: ID!
    $url: String!
    $quantity: Int!
    $expires_at: String
  ) {
    createInbulkQuantityInvite(
      agency_id: $agency_id
      account_id: $account_id
      promo_id: $promo_id
      url: $url
      quantity: $quantity
      expires_at: $expires_at
    ) {
      id
    }
  }
`;

export const CREATE_INBULK_FILE_INVITE = gql`
  mutation createInbulkFileInvite($agency_id: ID!, $account_id: ID!, $promo_id: ID!, $url: String!, $file: Upload!) {
    createInbulkFileInvite(
      agency_id: $agency_id
      account_id: $account_id
      promo_id: $promo_id
      url: $url
      file: $file
    ) {
      id
      file_headers
      quantity
    }
  }
`;

export const CREATE_INBULK_FILE_MAPPER = gql`
  mutation createInbulkFileMapper(
    $agency_id: ID!
    $account_id: ID!
    $generator_id: ID!
    $data: String!
    $name: String
    $uid_field_slug: String
  ) {
    createInbulkFileMapper(
      agency_id: $agency_id
      account_id: $account_id
      generator_id: $generator_id
      data: $data
      name: $name
      uid_field_slug: $uid_field_slug
    ) {
      id
    }
  }
`;

export const INVITELINKS_GENERATOR = gql`
  query inviteLinksGenerator(
    $agency_id: ID!
    $account_id: ID!
    $promo_id: ID!
    $page: Int
    $filters: InviteLinksInBulkFilter
  ) {
    inviteLinksGenerator(
      agency_id: $agency_id
      account_id: $account_id
      promo_id: $promo_id
      page: $page
      filters: $filters
    ) {
      data {
        id
        agency_id
        account_id
        promotion_id
        user_id
        type
        url
        quantity
        generated_quantity
        origin_file
        generated_file
        file_headers
        failed_rows
        failed_attempts
        failed_message
        expires_at
        status
        generated_at
        created_at
        user {
          first_name
          last_name
        }
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const INVITELINK_DOWNLOAD_FILE = gql`
  query inviteLinkDownloadFile($agency_id: ID!, $account_id: ID!, $generator_id: ID!) {
    inviteLinkDownloadFile(agency_id: $agency_id, account_id: $account_id, generator_id: $generator_id) {
      temporary_url
    }
  }
`;

export const INVITELINK_EXPORT = gql`
  query inviteLinkExport($agencyId: ID!, $accountId: ID!, $promoId: ID!, $filters: InviteLinksExportFilter) {
    inviteLinkExport(agencyId: $agencyId, accountId: $accountId, promoId: $promoId, filters: $filters) {
      temporary_url
    }
  }
`;

export const INVITELINK_GENERATOR = gql`
  query inviteLinkGenerator($agency_id: ID!, $account_id: ID!, $id: ID!) {
    inviteLinkGenerator(agency_id: $agency_id, account_id: $account_id, id: $id) {
      id
      agency_id
      account_id
      promotion_id
      user_id
      type
      url
      quantity
      generated_quantity
      origin_file
      generated_file
      file_headers
      failed_rows
      failed_attempts
      failed_message
      expires_at
      status
      generated_at
      created_at
    }
  }
`;

export const REFRESH_PASSCODE = gql`
  mutation refreshPasscode($accountId: ID!, $promoId: ID!) {
    refreshPasscode(accountId: $accountId, promoId: $promoId) {
      passcode
      passcode_time
      current_time
    }
  }
`;

export const EXPIRATION_DATE_INVITE_LINK = gql`
  mutation expireInviteLink($agencyId: ID!, $accountId: ID!, $code: String!) {
    expireInviteLink(agencyId: $agencyId, accountId: $accountId, code: $code) {
      id
      expires_at
    }
  }
`;

export const DELETE_INVITELINK_GENERATOR = gql`
  mutation deleteInviteLinkGenerator($agencyId: ID!, $accountId: ID!, $id: ID!) {
    deleteInviteLinkGenerator(agencyId: $agencyId, accountId: $accountId, id: $id) {
      id
      message
    }
  }
`;

export const API_KEY = gql`
  query apiKey($agencyId: ID!, $accountId: ID!) {
    apiKey(agencyId: $agencyId, accountId: $accountId) {
      id
      service_client_id
      service_secret
    }
  }
`;

export const CREATE_API_KEY = gql`
  mutation createApiKey($agencyId: ID!, $accountId: ID!) {
    createApiKey(agencyId: $agencyId, accountId: $accountId) {
      id
      service_client_id
      service_secret
    }
  }
`;

export const REMOVE_API_KEY = gql`
  mutation removeApiKey($agencyId: ID!, $accountId: ID!) {
    removeApiKey(agencyId: $agencyId, accountId: $accountId) {
      message
    }
  }
`;

export const UPDATE_DOMAIN_MASK = gql`
  mutation updateDomainMask(
    $accountId: ID!
    $promoId: ID!
    $customSlug: String!
    $enableCustomDomain: Boolean
    $customDomain: String
  ) {
    updateDomainMask(
      accountId: $accountId
      promoId: $promoId
      customSlug: $customSlug
      enableCustomDomain: $enableCustomDomain
      customDomain: $customDomain
    ) {
      id
      microsite_url
      slug
      external_domain
      enable_custom_domain
      domain_type
    }
  }
`;

export const UPDATE_PROMOTE_INTEGRATION = gql`
  mutation updateIntegration($accountId: ID!, $promoId: ID!, $isEnabled: Boolean!) {
    updateIntegration(accountId: $accountId, promoId: $promoId, isEnabled: $isEnabled) {
      ...promotion_Part
    }
  }
  ${promotionFragment}
`;

export const LIST_DOMAIN_TYPES = gql`
  query domainTypes($accountId: ID!, $promoId: ID!) {
    domainTypes(accountId: $accountId, promoId: $promoId) {
      name
      value
      enable
    }
  }
`;

export const UPDATE_DOMAIN_TYPE = gql`
  mutation updateDomainType($accountId: ID!, $promoId: ID!, $customSlug: String!, $domainType: Int!) {
    updateDomainType(accountId: $accountId, promoId: $promoId, customSlug: $customSlug, domainType: $domainType) {
      microsite_url
      slug
      external_domain
      domain_type
      enable_custom_domain
    }
  }
`;
