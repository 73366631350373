import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { NavigationMobileProps } from './interface';
import { IconButton } from '../DesignSystem/Inputs/IconButton';
import { ReactComponent as HexagonIcon } from '../../assets/imgs/hexagon.svg';
import styleModuleClasses from '../../utils/styleModuleClasses';
import { NavigationMobileLinks } from '../../config/NavigationLinksData';
import { OpenHubspotChat } from '../OpenHubspotChat';
import hasPermission from '../../utils/hasPermission';
import styles from './styles.module.scss';

export default function NavigationMobile({
  accountSlug,
  accountPermission,
  userData,
}: NavigationMobileProps): JSX.Element {
  const [mobileNav, setMobileNav] = useState<boolean>(false);
  const history = useHistory();

  const { openHubspotChat } = OpenHubspotChat(userData);

  const setMobileNavCollapsed = () => {
    setMobileNav((mobileNav) => !mobileNav);
  };

  const handleAction = (type: string, action: string) => {
    if (type === 'link') {
      history.push(action);
    } else if (type === 'redirect') {
      window.location.href = action.replace(':accountSlug', accountSlug);
    } else if (type === 'function') {
      const functions = {
        setMobileNavCollapsed: () => setMobileNavCollapsed(),
        openHubspotChat: () => openHubspotChat(),
      };

      const dynamicFunction = functions[action as keyof typeof functions];
      if (dynamicFunction) {
        dynamicFunction();
      } else {
        console.log(`Unknown action: ${action}`);
      }
    }
  };

  const itemsHidden = NavigationMobileLinks.hidden.filter((option) => {
    if (option.accountPermission) {
      return hasPermission(accountPermission, option.accountPermission);
    }
    return true;
  });

  return (
    <div className={styleModuleClasses(styles, 'mobileNav-wrapper', 'hidden-up-lg')}>
      <ul>
        {NavigationMobileLinks.main.map((option) => (
          <li
            key={`options-mobile-nav-${option.icon}`}
            className={styleModuleClasses(styles, {
              cta: Boolean(option.cta),
              selected:
                option.id !== undefined &&
                location.pathname !== '/promotions/create' &&
                location.pathname.indexOf(option.id) > -1,
            })}
            onClick={() => handleAction(option.type, option.action)}
          >
            {option.cta && <HexagonIcon />}
            <IconButton text={option.title}>{option.icon}</IconButton>
          </li>
        ))}
      </ul>
      <div
        className={styleModuleClasses(styles, 'hidden-sidebar-wrapper', {
          'slide-in': mobileNav,
          'slide-out': !mobileNav,
        })}
      >
        <button className={styles['collapse-arrow']} onClick={setMobileNavCollapsed} />
        <ul>
          {itemsHidden.map((option) => {
            return (
              <li
                key={`options-mobile-nav-hidden-${option.title}`}
                className={styleModuleClasses(styles, {
                  selected: option.id !== undefined && location.pathname.indexOf(option.id) > -1,
                })}
                onClick={() => handleAction(option.type, option.action)}
              >
                <IconButton text={option.title}>{option.icon}</IconButton>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
