import styleModuleClasses from '../../../utils/styleModuleClasses';
import { PillsProps } from './interface';
import styles from './styles.module.scss';

export default function Pills({
  size,
  color,
  firstIcon,
  lastIcon,
  children,
  propRef,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
}: PillsProps): JSX.Element {
  return (
    <div
      ref={propRef}
      className={[
        styles['pills'],
        size ? styles[size] : styles['sm'],
        color ? styles[color] : styles['primary'],
        className,
      ].join(' ')}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {firstIcon?.children && (
        <>
          {firstIcon.customFont ? (
            <span className={styleModuleClasses(styles, 'custom', firstIcon?.customClass, firstIcon?.children)}></span>
          ) : (
            <span style={{ transform: firstIcon?.style?.transform }} className="material-symbols-rounded">
              {firstIcon?.children}
            </span>
          )}
        </>
      )}
      <span>{children}</span>
      {lastIcon?.children && (
        <>
          {lastIcon.customFont ? (
            <span className={styleModuleClasses(styles, 'custom', lastIcon?.customClass, lastIcon?.children)}></span>
          ) : (
            <span style={{ transform: lastIcon?.style?.transform }} className="material-symbols-rounded">
              {lastIcon?.children}
            </span>
          )}
        </>
      )}
    </div>
  );
}
