import { MutableRefObject } from 'react';
import { IconProps } from '../Icon/interface';

export interface PillsProps {
  color?: PillsColors;
  propRef?: MutableRefObject<null>;
  size?: 'sm' | 'lg';
  firstIcon?: IconProps;
  lastIcon?: IconProps;
  children: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  className?: string;
}

export enum PillsColors {
  red = 'red',
  redDark = 'red-dark',
  yellow = 'yellow',
  yellowDark = 'yellow-dark',
  gray = 'gray',
  grayDark = 'gray-dark',
  primary = 'primary',
  primaryDark = 'primary-dark',
  green = 'green',
  greenDark = 'green-dark',
  rainbow = 'rainbow',
  rainbowDark = 'rainbow-dark',
}
