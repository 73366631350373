import { useEffect, useRef } from 'react';
import { usePopper } from 'react-popper';
import styleModuleClasses from '../../../utils/styleModuleClasses';
import { Button } from '../Inputs/Buttons/ButtonAction';
import Overlay from '../Overlay';
import { PopoverProps } from './interface';
import styling from './styles.module.scss';

export default function Popover({
  header,
  placement,
  buttonsFooter,
  size,
  children,
  color,
  propRef,
  showPopover,
  hiddenOverlay,
  mainStyle,
  callback,
  customClassArrow,
}: PopoverProps): JSX.Element {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const { styles, attributes, update } = usePopper(propRef.current, dropdownRef.current, {
    placement: placement || 'auto',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      {
        name: 'arrow',
        options: {
          element: arrowRef.current,
        },
      },
    ],
  });

  useEffect(() => {
    if (showPopover && update) {
      update();
    }
  }, [showPopover, update]);

  return (
    <>
      {showPopover && !hiddenOverlay && <Overlay callback={callback} />}
      <div
        className={[
          styling['popover-wrapper'],
          size ? styling[size] : '',
          color ? styling[color] : '',
          styling[showPopover ? 'fade-in' : 'fade-out'],
        ].join(' ')}
        ref={dropdownRef}
        style={styles.popper}
        {...attributes.popper}
      >
        <div
          className={styleModuleClasses(styling, 'arrow', customClassArrow)}
          ref={arrowRef}
          style={styles.arrow}
          data-popper-arrow
        />
        {header && (
          <header className={header.className}>
            <span>{header.title}</span>
            {header.button && (
              <Button variant="tertiary" size="sm" {...header.button}>
                {header.button.children}
              </Button>
            )}
          </header>
        )}
        {!!children && <main style={mainStyle}>{children}</main>}
        {buttonsFooter && buttonsFooter.length > 0 && (
          <footer>
            {buttonsFooter.map((button, index) => (
              <Button key={`button-footer-dropdown-${index}`} {...button}>
                {button?.children}
              </Button>
            ))}
          </footer>
        )}
      </div>
    </>
  );
}
