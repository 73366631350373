import { ChangeEvent, useEffect, useState } from 'react';
import Alert from '../../../../componentsV2/DesignSystem/Alert';
import { IconAnimated } from '../../../../componentsV2/DesignSystem/IconAnimated';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import Input from '../../../../componentsV2/DesignSystem/Inputs/Input';
import Modal from '../../../../componentsV2/DesignSystem/Modal';
import { FontWeight, Paragraph } from '../../../../componentsV2/DesignSystem/Typography';
import useAccount from '../../../../hooks/useAccount';
import useAuth from '../../../../hooks/useAuth';
import { TableHeaderButtonsProps } from './interface';
import styles from './styles.module.scss';
import { useExpiryInviteLinksMutation } from './useExpiryInviteLinksMutation';
import { useExportInviteLinksMutation } from './useExportInviteLinksMutation';

export default function TableHeaderButtons({
  invitesSelected,
  refreshInviteLinks,
  filters,
  exportFields,
}: TableHeaderButtonsProps): JSX.Element {
  const [showExpiryResult, setShowExpireResult] = useState<boolean>(false);
  const [showExportInviteLinks, showSetExportInviteLinks] = useState<boolean>(false);
  const [expireInput, setExpireInput] = useState<string>();

  const { getAccount } = useAccount();
  const { getMe } = useAuth();

  const { expiryInviteLinks, data: dataExpiry, loading: loadingExpiry } = useExpiryInviteLinksMutation();
  const { exportInviteLinks, data: dataExport, loading: loadingExport } = useExportInviteLinksMutation();

  const handleExpireInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setExpireInput(value);
  };

  const handleExportList = () => {
    exportInviteLinks?.(getAccount.accountId, filters, exportFields);
  };

  useEffect(() => {
    if (dataExpiry?.random) {
      closeExpiryModal();
      refreshInviteLinks();
    }
  }, [dataExpiry?.random]);

  useEffect(() => {
    if (dataExport?.random) {
      showSetExportInviteLinks(true);
    }
  }, [dataExport?.random]);

  const confirmExpiryInviteLinks = () => {
    expiryInviteLinks?.(getAccount.agencyId, getAccount.accountId, invitesSelected.join(','));
  };

  const closeExpiryModal = () => {
    setShowExpireResult(false);
    setExpireInput('');
  };

  const closeExportModal = () => {
    showSetExportInviteLinks(false);
  };

  return (
    <>
      <div>
        <Button
          variant="secondary"
          firstIcon={{ children: 'file_download' }}
          onClick={handleExportList}
          disabled={loadingExport}
          loading={loadingExport}
        >
          Export List
        </Button>
        {/*
          @TODO: waiting for the feature
          <Button variant="secondary" firstIcon={{ children: 'email' }}>
            Send Email of results
          </Button>
        */}
        <Button
          variant="secondary"
          firstIcon={{ children: 'expire ', customFont: true }}
          onClick={() => setShowExpireResult(true)}
          disabled={!invitesSelected.length}
        >
          Expire Invites
        </Button>
      </div>
      {showExpiryResult && (
        <Modal
          title="Really Expire"
          callback={closeExpiryModal}
          size="small"
          leftButton={{
            children: 'Cancel',
            variant: 'secondary',
            size: 'sm',
            onClick: closeExpiryModal,
            disabled: loadingExpiry,
          }}
          rightButton={{
            children: 'Confirm',
            size: 'sm',
            onClick: confirmExpiryInviteLinks,
            loading: loadingExpiry,
            disabled: expireInput !== 'EXPIRE',
          }}
        >
          <div className={styles['modal-expiry-result']}>
            <Paragraph size={2} weight={FontWeight.semiBold}>
              Are you sure you want to Expire?
            </Paragraph>
            <Alert color="danger-light">These InviteLinks will be expire</Alert>
            <Input
              label="Type EXPIRE to confirm:"
              placeholder="EXPIRE"
              onChange={handleExpireInput}
              value={expireInput}
            />
          </div>
        </Modal>
      )}
      {showExportInviteLinks && (
        <Modal title="Export List" callback={closeExportModal} size="small">
          <div className={styles['modal-export']}>
            <IconAnimated wrapper={{ color: 'green' }} icon={{ name: 'check' }} />
            <div>
              <Paragraph size={2} weight={FontWeight.semiBold}>
                Your export is processing and will be sent in the next 15 minutes to:
              </Paragraph>
              <Paragraph size={2} weight={FontWeight.extraBold}>
                {getMe.email}
              </Paragraph>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
