import { InputHTMLAttributes } from 'react';
import styleModuleClasses from '../../../../utils/styleModuleClasses';
import styles from './styles.module.scss';

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: 'secondary';
}

export default function Switch(props: SwitchProps): JSX.Element {
  return (
    <div className={styleModuleClasses(styles, 'form-check', props.variant)}>
      <input className={styles['form-check-input']} type="checkbox" {...props} />
    </div>
  );
}
