import { useHistory, useLocation } from 'react-router-dom';

const useQueryParams = (): ((params: Record<string, string | number | undefined>) => void) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const addQueryParam = (params: Record<string, string | number | undefined>) => {
    const newQuery = new URLSearchParams(query.toString());

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        newQuery.set(key, String(value));
      } else {
        newQuery.delete(key);
      }
    }

    history.replace(`?${newQuery.toString()}`);
  };

  return addQueryParam;
};

export default useQueryParams;
