import { forwardRef } from 'react';
import Icon from '../../../Icon';
import styles from '../styles.module.scss';
import { ActionProps } from './interface';

const Button = forwardRef<HTMLButtonElement, ActionProps>(
  (
    {
      children,
      firstIcon,
      lastIcon,
      variant,
      loading,
      text,
      fullWidth,
      customClass,
      align,
      size,
      borderStyle,
      ...rest
    }: ActionProps,
    ref,
  ) => (
    <button
      ref={ref}
      className={[
        styles['button'],
        variant ? styles[variant] : styles['primary'],
        text?.color ? styles['text-' + text.color] : '',
        size ? styles[size] : styles['md'],
        fullWidth ? styles['fullwidth'] : '',
        align ? styles[align] : '',
        borderStyle ? styles[borderStyle] : '',
        customClass ? customClass : '',
      ].join(' ')}
      type="button"
      {...rest}
    >
      <div className={`${styles['content']} ${text?.align ? styles[text.align] : styles['center']}`}>
        {firstIcon?.children &&
          (loading ? (
            <Icon customClass={styles['loading-icon']}>progress_activity</Icon>
          ) : (
            <Icon customClass={styles['icon']} {...firstIcon}>
              {firstIcon.children}
            </Icon>
          ))}
        {children && (
          <main className={!firstIcon?.children && !lastIcon?.children && loading ? styles['loading'] : ''}>
            <span>{children}</span>
            <Icon customClass={styles['loading-icon']}>progress_activity</Icon>
          </main>
        )}
        {lastIcon?.children &&
          (loading ? (
            <Icon customClass={styles['loading-icon']}>progress_activity</Icon>
          ) : (
            <Icon customClass={styles['icon']} {...lastIcon}>
              {lastIcon.children}
            </Icon>
          ))}
      </div>
    </button>
  ),
);

Button.displayName = 'Button';

export { Button };
